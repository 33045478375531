import React from 'react'
import { Fade } from 'react-reveal'
import classNames from 'classnames'

import { RowLayout } from '../../../components/layout'
import Page from '../../templates/Page'
import PageSection from '../../templates/PageSection'

import './licensing.css'
import stationImage from '../../assets/old-images/licensing/hero/notebook.png'
// import bmiLogo from '../../assets/old-images/licensing/bmi.png'
// import ascapLogo from '../../assets/old-images/licensing/ascap.png'
// import sxLogo from '../../assets/old-images/licensing/sound-exchange.png'
// import sesacLogo from '../../assets/old-images/licensing/sesac.png'
// import socanLogo from '../../assets/old-images/licensing/socan.jpg'
// import resoundLogo from '../../assets/old-images/licensing/resound.jpg'
// import prsLogo from '../../assets/old-images/licensing/prs.png'
// import pplLogo from '../../assets/old-images/licensing/ppl.jpg'

class Licensing extends React.Component {
  render() {
    return (
      <Page
        className={classNames('licensing', this.props.className)}
        headerTitle="Music Licensing"
        headerContent={headerContent}
      >
        <PageSection className={classNames('music-licensing')}>
          <RowLayout isResponsive breakPoint={1000} itemSpacing={'4vw'}>
            <div className={classNames('column', 'padded-section-content')}>
              <Fade top duration={700} fraction={0.9}>
                <h1 className={classNames('section-title')}>
                  Music Licensing Covered
                </h1>
                <p className={classNames('section-text')}>
                  Live365 covers webcast transmissions on Live365 in the USA,
                  Canada, and the United Kingdom. The USA is covered via ASCAP,
                  BMI, SESAC, and SoundExchange, Canada is covered via SOCAN and
                  Re:Sound, and the UK is covered via PPL and PRS for Music. Our
                  coverage is provided through finalized agreements, interim
                  agreements, and consent decrees with respective collection
                  societies.
                </p>
              </Fade>
            </div>
          </RowLayout>
        </PageSection>

        <PageSection
          className={classNames('custom-licensing')}
          style={{ backgroundColor: '#F7F7F7' }}
        >
          <RowLayout isResponsive breakPoint={1000} itemSpacing={'4vw'}>
            <div className={classNames('column', 'padded-section-content')}>
              <Fade top duration={700} fraction={0.9}>
                <h1 className={classNames('section-title')}>
                  Custom Solutions
                </h1>
                <p className={classNames('section-text')}>
                  Don’t need licensing for your station, or already have your
                  own in place? We have custom packages for customers who don’t
                  need licensing. Contact us at{' '}
                  <a href="mailto:sales@live365.com">sales@live365.com</a>.
                </p>
              </Fade>
            </div>
          </RowLayout>
        </PageSection>

        {this.props.children}
      </Page>
    )
  }
}

const headerContent = (
  <RowLayout isResponsive itemSpacing={'4vw'}>
    <img src={stationImage} alt="Live365 station" />

    <p className={classNames('body-text')}>
      By broadcasting with Live365, your station is covered for transmissions in
      the USA, Canada, and the UK (see "Music Licensing Covered" below). Let us
      handle the complex reporting and legal negotiations while you curate the
      best content on the Internet.
    </p>
  </RowLayout>
)

export default Licensing
